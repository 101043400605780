import React from 'react';

const DcStat = (props) => {
  const { value } = props

  return (
    <div className='gas-dc-stat'>
        <div className={`gas-dc-stat__icon gas-dc-stat__icon--${value.replace(' ','-').toLowerCase()}`} />
        {value}
    </div>
  );
}

export default DcStat;