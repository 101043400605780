import React, { useState } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Toon from '../toon';
import './style.scss';
import DcFilter from './dcFilter';

const season3v3 = '33';
const season5v5 = '34';

const CounterFilters = (props) => {
  const [toons, setToons] = useState([]);
  const [metaParams, setMetaParams] = useState([]);

  const addToons = (e, v) => {
    let updateToons = [
      ...toons,
      v
    ]
    setToons(updateToons);
    props.addToons(updateToons);
    metaQuery(updateToons);
  }

  const removeToons = () => {
    let updateToons = [...toons].slice(0, -1);
    setToons(updateToons);
    props.addToons(updateToons);
    metaQuery(updateToons);
  }

  const onSearchBy = (v) => {
    props.changeSearchby(v);
    if (v !== props.searchBy) {
      setToons([]);
    }
  }

  const metaQuery = (data) => {
    let query = data.length > 1 && data.map(t => `&d_unit=${t.code}`);
    query.length > 0 && query.shift();
    setMetaParams(query.length > 0 ? query.join('') : []);
  }

  return (
    <Box className="gas-filters">
      <Box className="gas-filters__group">
        <ButtonGroup>
          <Button
            onClick={() => onSearchBy('def')} color="primary"
            variant={props.searchBy === 'def' && 'contained'}
          >Defense</Button>
          <Button
            onClick={() => onSearchBy('atk')} color="primary"
            variant={props.searchBy === 'atk' && 'contained'}
          >Offense</Button>
        </ButtonGroup>
      </Box>
      <Box className="gas-filters__group">
        <ButtonGroup>
          <Button component={NavLink} variant={props.type === '5v5' && 'contained'} to="/counters/">5v5</Button>
          <Button component={NavLink} variant={props.type === '3v3' && 'contained'} to="/counters/3v3/">3v3</Button>
          <Button component={NavLink} variant={props.type === 'fleet' && 'contained'} to="/counters/fleet/">Ships</Button>
        </ButtonGroup>
      </Box>
      {props.type !== 'fleet' &&
        <Box className="gas-filters__group">
          <FormGroup>
            <FormControlLabel control={<Switch color="primary" checked={props.showExpDcs} onChange={props.toggleExpDcs} />} label="Expired DCs" />
          </FormGroup>
        </Box>
      }
      <Box className="gas-filters__group">
        <Box className={classNames('gas-filters__toon', toons.length < 0 && 'gas-filters__toon--disabled', toons.length === 0 && 'gas-filters__toon--enabled')}>
          <Toon
            src={toons[0] && toons[0].imageReference} add list={props.leaders} onAdd={addToons}
            remove={toons.length === 1} onRemove={removeToons} type={props.type}
          />
        </Box>
        <Box className={classNames('gas-filters__toon', toons.length < 1 && 'gas-filters__toon--disabled', toons.length === 1 && 'gas-filters__toon--enabled')}>
          <Toon
            src={toons[1] && toons[1].imageReference} add list={props.squad} onAdd={addToons}
            remove={toons.length === 2} onRemove={removeToons} type={props.type}
          />
        </Box>
        <Box className={classNames('gas-filters__toon', toons.length < 2 && 'gas-filters__toon--disabled', toons.length === 2 && 'gas-filters__toon--enabled')}>
          <Toon
            src={toons[2] && toons[2].imageReference} add list={props.squad} onAdd={addToons}
            remove={toons.length === 3} onRemove={removeToons} type={props.type}
          />
        </Box>
        {(props.type === '5v5' || props.type === 'fleet') &&
          <Box className={classNames('gas-filters__toon', toons.length < 3 && 'gas-filters__toon--disabled', toons.length === 3 && 'gas-filters__toon--enabled')}>
            <Toon
              src={toons[3] && toons[3].imageReference} add list={props.squad} onAdd={addToons}
              remove={toons.length === 4} onRemove={removeToons} type={props.type}
            />
          </Box>
        }
        {(props.type === '5v5' || props.type === 'fleet') &&
          <Box className={classNames('gas-filters__toon', toons.length < 4 && 'gas-filters__toon--disabled', toons.length === 4 && 'gas-filters__toon--enabled')}>
            <Toon
              src={toons[4] && toons[4].imageReference} add list={props.squad} onAdd={addToons}
              remove={toons.length === 5} onRemove={removeToons} type={props.type}
            />
          </Box>
        }
        {props.type === 'fleet' &&
          <Box className={classNames('gas-filters__toon', toons.length < 5 && 'gas-filters__toon--disabled', toons.length === 5 && 'gas-filters__toon--enabled')}>
            <Toon
              src={toons[5] && toons[5].imageReference} add list={props.squad} onAdd={addToons}
              remove={toons.length === 6} onRemove={removeToons} type={props.type}
            />
          </Box>
        }
        {props.type === 'fleet' &&
          <Box className={classNames('gas-filters__toon', toons.length < 6 && 'gas-filters__toon--disabled', toons.length === 6 && 'gas-filters__toon--enabled')}>
            <Toon
              src={toons[6] && toons[6].imageReference} add list={props.squad} onAdd={addToons}
              remove={toons.length === 7} onRemove={removeToons} type={props.type}
            />
          </Box>
        }
        {props.type === 'fleet' &&
          <Box className={classNames('gas-filters__toon', toons.length < 7 && 'gas-filters__toon--disabled', toons.length === 7 && 'gas-filters__toon--enabled')}>
            <Toon
              src={toons[7] && toons[7].imageReference} add list={props.squad} onAdd={addToons}
              remove={toons.length === 8} onRemove={removeToons} type={props.type}
            />
          </Box>
        }
      </Box>
      {props.type !== 'fleet' && props.leader &&
        <Box className="gas-filters__group">
          <DcFilter dcThree={props.dcThree} dcSix={props.dcSix} dcNine={props.dcNine} changeDcs={props.changeDcs} disabled={!props.leader} />
        </Box>
      }
      {props.type !== 'fleet' && toons.length > 0 &&
        <Box className="gas-filters__group">
          <Button
            onClick={() => window.open(`https://swgoh.gg/gac/${props.searchBy === 'def' ? 'counters' : 'who-to-attack'}/${toons[0].code}/?season=${props.type === '5v5' ? season5v5 : season3v3}${props.searchBy === 'def' && metaParams ? metaParams : ''}`)} color="primary"
            endIcon={<OpenInNewIcon />}
          >Meta Report</Button>
        </Box>
      }
    </Box>
  );
}

export default CounterFilters;