import React, { useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import CounterDialog from './dialog';

const DcFilter = (props) => {

  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState({ level3: null, level6: null, level9: null });
  const [dcs, setDcs] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onAddChange = (e, v, reason, level) => {
    let updateFilter = { ...filter, [`level${level}`]: v };
    setFilter(updateFilter);
  }

  const onApply = () => {
    let updateDcs = [];
    filter.level3 && updateDcs.push(filter.level3.code);
    filter.level6 && updateDcs.push(filter.level6.code);
    filter.level9 && updateDcs.push(filter.level9.code);
    setDcs(updateDcs);
    props.changeDcs && props.changeDcs(updateDcs);
    handleClose();
  }

  const DcAutocomplete = (props) => <Box mb={3} className="gas-filter-dc__autocomplete">
    <Autocomplete
      id={`level${props.level}`}
      value={filter[`level${props.level}`]}
      options={props.options}
      getOptionLabel={(option) => option.nickname}
      renderInput={(params) => <TextField {...params} label={`Level ${props.level}`} variant="filled" color="primary" />}
      renderOption={(option) => <Box className="gas-filter-dc__option">
        <Box className="gas-filter-dc__nickname">
          <Avatar src={`https://game-assets.swgoh.gg/${option.image}.png`} alt="" className="gas-filter-dc__avatar" />{option.nickname}
        </Box>
        <Box className="gas-filter-dc__description">{option.description}</Box>
      </Box>
      }
      filterOptions={createFilterOptions({ stringify: option => `${option.description} ${option.nickname} ${option.targetName}` })}
      onChange={(e, v, reason) => onAddChange(e, v, reason, props.level)}
    />
  </Box>;

  return (
    <Box className="gas-filter-dc">
      <Button
        onClick={handleClickOpen}
        variant={dcs.length === 0 ? 'outlined' : 'contained'}
        endIcon={dcs.length === 0 ? <AddIcon /> : <DoneIcon />}
        disabled={props.disabled}
      >Datacrons</Button>
      <CounterDialog
        open={open} onClose={handleClose} title="Select Datacrons"
      >
        <DcAutocomplete level={3} options={props.dcThree} />
        <DcAutocomplete level={6} options={props.dcSix} />
        <DcAutocomplete level={9} options={props.dcNine} />
        <Box display="flex">
          <Box>
            <Button onClick={onApply} variant='contained' color='primary'>Apply</Button>
          </Box>
          <Box ml={2}>
            <Button onClick={handleClose} variant='outlined' color='primary'>Cancel</Button>
          </Box>
        </Box>
      </CounterDialog>
    </Box>
  );
}

export default DcFilter;