
import { useState } from 'react'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Popover from '@material-ui/core/Popover'
import Avatar from '@material-ui/core/Avatar'
import AddIcon from '@material-ui/icons/Add';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { IconButton } from '@material-ui/core'
import Box from '@material-ui/core/Box'

const Unit = (props) => {
  const { options, selected, onChange, placement, type, onRemove } = props;
  const [inputValue, setInputValue] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box className="gas-toon-image gas-toon-image--small" marginRight={1}>
      {selected && <Box className="gas-toon-image__container">
        <img src={`https://game-assets.swgoh.gg/${selected.imageReference}.png`} alt="" className="gas-toon-image__src" />
        <Box className="gas-toon-image__remove">
          <IconButton onClick={() => onRemove(placement, type)} style={{ padding: 0 }}>
            <RemoveCircle fontSize="small" />
          </IconButton>
        </Box>
      </Box>}

      {!selected && <Avatar
        onClick={handleClick}
        className='gas-unit__avatar'
      >
        <AddIcon />
      </Avatar>
      }

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Autocomplete
          disableClearable
          value={selected}
          onChange={(e, newValue) => {
            onChange(newValue, placement, type);
            handleClose();
          }}
          inputValue={inputValue}
          onInputChange={(e, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={(option) => option ? option.name : ''}
          id="searchUnits"
          options={options}
          style={{ width: 240 }}
          renderInput={(params) => <TextField {...params} hiddenLabel variant='outlined' />}
        />
      </Popover>
    </Box>
  );
}

export default Unit;