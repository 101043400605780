import React from 'react';
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import Datacrons from '../components/datacrons';

const DatacronsPage = (props) => {

  return (
    <Box>
      <Container>
        <Box my={4}>
          <Typography align="center" variant="h4" component="h1" gutterBottom>
            Datacron Recommendations
          </Typography>
          <Typography align="center" variant="h6" component="h2" gutterBottom>
            These are recommendations for what stats and abilities to look for on current datacrons. This is updated dynamically and any suggestions, feedback or questions are welcome at discord.grandarenascience.com.
          </Typography>
          <Box>
            <Box display='flex' justifyContent='space-between' marginTop={2}>
              <ButtonGroup>
                <Button component={NavLink} variant={props.type === '5v5' && 'contained'} to="/datacrons/">5v5</Button>
                <Button component={NavLink} variant={props.type === '3v3' && 'contained'} to="/datacrons/3v3/">3v3</Button>
              </ButtonGroup>
              <Box display='flex' >
                <Box display='flex' alignItems='center'>
                  <Avatar src='/images/use-attack.png' alt='Offense' className='gas-toon-image__avatar gas-toon-image__avatar--primary gas-toon-image__avatar--inline' />
                  <Box marginLeft={1} marginRight={4}>= Use on Offense</Box>
                </Box>
                <Box display='flex' alignItems='center'>
                  <Avatar src='/images/use-defense.png' alt='Defense' className='gas-toon-image__avatar gas-toon-image__avatar--secondary gas-toon-image__avatar--inline' />
                  <Box marginLeft={1}>= Use on Defense</Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Datacrons type={props.type} />
        </Box>
      </Container>
    </Box>
  );
}

export default withRouter(DatacronsPage);